<template>
  <div>
    <div class="title">单位列表</div>
    <div class="bcg">
      <ul class="box">
        <li v-for="(item, index) in menulist " :key="index" @click="tab(item.tenantId)">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-yiyuan1"></use>
          </svg>
          <span></span>{{ item.tenantName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { selectHospitolAPI } from '@/api/index';
export default {
  data() {
    return {
      menulist: []
    };
  },
  created() {
    this.selectHospital()
    // console.log("refer:", windows.localStorage.href);
  },
  methods: {
    selectHospital() {
      selectHospitolAPI().then((res) => {
        console.log(res.data.data.tenantList, '55');
        this.menulist = res.data.data.tenantList;
        console.log(this.menulist, 'this.menulist');
      }).catch((error) => {
        this.show = false;
        console.log(error);
      });

    },
    tab(code) {
      console.log(code);
      this.$router.push({ path: '/bindingOfMobile', query: { code } });
    },
  },
};
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-left: 50px;
  margin-right: 10px;
  overflow: hidden;
  font-size: 32px;
  margin-right: 25px;
}

.title {
  text-align: center;
  color: white;
  background-color: #0052d4;
  font-size: 0.6rem;
  padding-top: 50px;
  box-sizing: border-box;
}

.bcg {
  height: 100px;
  background-image: linear-gradient(to bottom, #0052d4, #4367f7, #6fb1fc);
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.box>li {
  width: 80%;
  height: 70px;
  line-height: 70px;
  /* padding-left: 2rem; */
  margin-top: 30px;
  box-shadow: 0 1px 5px #999;
  font-size: 16px;
  z-index: 6;
  background-color: white;
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  align-self: flex-start
}

ul>li:first-child {
  margin-top: 60px;
}

li>span {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 15px;
  /* margin-top: 5px; */
  border: 2px solid silver;
  border-top-left-radius: 5px;
}

li:nth-child(2) span {
  border: 2px solid green;
}

li:nth-child(3) span {
  border: 2px solid brown;
}

li:nth-child(4) span {
  border: 2px solid gold;
}

li:nth-child(5) span {
  border: 2px solid paleturquoise;
}
</style>
